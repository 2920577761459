// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBJx7EtmOSB52y2K_VFvjPdQ7z4AyZkiEA",
    authDomain: "noiremarche.firebaseapp.com",
    databaseURL: "https://noiremarche.firebaseio.com",
    projectId: "noiremarche",
    storageBucket: "noiremarche.appspot.com",
    messagingSenderId: "756081843143",
    appId: "1:756081843143:web:8bc20878bbe477f23ed7e2",
    measurementId: "G-CNK12FP5E8"
  },
  algolia: {
    appId: 'IWY54T48E5',
    apiKey: '2e113ff445bf53f3fad9060ff0de0f7a'
  },
  stripe: { client_id: 'ca_IIbAvksK3aknY34VA96GbZDai7EjdHPK' }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
