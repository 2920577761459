import { Pipe, PipeTransform } from '@angular/core';
import * as plural from 'pluralize';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {

  transform(word: string, amount: number): string {
    return plural(word, amount);
  }

}
