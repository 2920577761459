import { Pipe, PipeTransform } from '@angular/core';
import country from 'country-list-js';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {

  transform(code): string {
    return country.findByIso2(code).name;
  }

}
