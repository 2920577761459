import { Pipe, PipeTransform } from '@angular/core';
import { flag } from 'country-emoji';

@Pipe({
  name: 'countryEmoji'
})
export class CountryEmojiPipe implements PipeTransform {

  transform(code: string): string {
    return flag(code);
  }

}
