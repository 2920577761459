import { Injectable } from '@angular/core';

import { Plugins, LocalNotificationScheduleResult } from '@capacitor/core';
const { LocalNotifications, PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  async schedule(title: string, body: string): Promise<LocalNotificationScheduleResult> {
    return await LocalNotifications.schedule({
      notifications: [
        {
          title,
          body,
          id: 1,
          schedule: {},
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null
        }
      ]
    });
  }

  async removeAllDeliveredNotifications() { await PushNotifications.removeAllDeliveredNotifications();}

}
