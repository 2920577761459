import { Component } from '@angular/core';

import { AuthService } from './services/auth/auth.service';
import { User } from './interfaces/user';
import { countries } from './countries';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  user      : User;
  platform  : string;
  countries : any[] = countries;
  constructor(
    public  auth  : AuthService,
    private router: Router,
    ) {}

  async ngOnInit() {
    this.auth.user$.subscribe(async (user: User) => { this.user = user; });
  }

  getCompanies(code: string) { this.navigateByUrl(`countries/${code}`); }

  navigateByUrl(url: string) { this.router.navigateByUrl(url); }
}
