import { Injectable } from '@angular/core';
import { Badge } from '@ionic-native/badge/ngx';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  constructor(private badge: Badge) { }
  async set(number: number) { await this.badge.set(number); }
  async increase(number: number) { await this.badge.increase(number); }
  async decrease(number: number) { await this.badge.decrease(number); }
  async clear() { await this.badge.clear(); }
}
