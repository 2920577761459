import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { User } from '../../interfaces/user';
import { AngularFirestore } from '@angular/fire/firestore';

import firebase from 'firebase/app';import 'firebase/firestore';
import 'firebase/auth';

import { Plugins } from '@capacitor/core';
import { BadgeService } from '../badge/badge.service';
import { NotificationsService } from '../notifications/notifications.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$   : Observable<User>;
  platform: string;
  private _token: string;
  constructor(
    private auth          : AngularFireAuth,
    private db            : AngularFirestore,
    private badge         : BadgeService,
    private notifications : NotificationsService,
    private alert         : AlertService
  ) {
    this.user$ = this.auth.authState.pipe(
      switchMap(user => {
        if (user) return this.db.doc<User>(`users/${user.uid}`).valueChanges();
        else return of(null);
      })
    )
  }

  getProviderId() { return firebase.auth().currentUser.providerData[0].providerId; }

  async sendEmailVerification() { await firebase.auth().currentUser.sendEmailVerification(); }

  public get emailVerified(): boolean {
    return firebase.auth().currentUser.emailVerified;
  }

  set token(token: string) { this._token = token; }

  get token() { return this._token; }

  async updatePassword(password: string) {
    try { await firebase.auth().currentUser.updatePassword(password); }
    catch (e) {}
  }

  async sendPasswordResetEmail(email: string) {
    try { await this.auth.sendPasswordResetEmail(email); }
    catch(e) {}
  }

  async createUser(uid: string, displayName: string, email: string, photoURL: string) {
    try {
      const { serverTimestamp } = firebase.firestore.FieldValue;
      return await this.db
      .doc<User>(`users/${uid}`)
      .set({
        uid,
        displayName,
        email,
        country: '',
        photoURL,
        countries : [],
        chats     : firebase.firestore.FieldValue.increment(0),
        bookmarks : firebase.firestore.FieldValue.increment(0),
        companies : firebase.firestore.FieldValue.increment(0),
        isAdmin   : false,
        timestamp : serverTimestamp(),
        stripe    : { stripe_connected_account: false }
      });
    }
    catch (e) { console.log(e); }
  }

  async createUserWithEmailAndPassword(email: string, password: string, displayName: string) {
    try {
      const { user } = await this.auth.createUserWithEmailAndPassword(email, password);
      const { uid } = user;
      await this.createUser(uid, displayName, email, '');
    }
    catch (e) { this.alert.present('', e); }
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<boolean> {
    try {
      await this.auth.signInWithEmailAndPassword(email, password);
      return true;
    }
    catch (e) {
      await this.alert.present('Error', e.message);
      return false;
    }
  }

  async signOut() { await this.auth.signOut(); }

  async deleteUser() {
    try {
      this.user$
      .pipe(take(1))
      .subscribe(async (user: User) => {
        await firebase.auth().currentUser.delete();
      });
    }
    catch (e) {}
  }

  async updateEmail(email: string) {
    try { return await firebase.auth().currentUser.updateEmail(email); }
    catch (e) {}
  }

  async signInWithGoogle() {
    try {
      const signIn = await Plugins.GoogleAuth.signIn();
      const credential = firebase.auth.GoogleAuthProvider.credential(signIn.authentication.idToken);
      const { user, additionalUserInfo } = await this.auth.signInWithCredential(credential);
      const { uid, displayName, photoURL, email, emailVerified, sendEmailVerification } = user;
      const { isNewUser } = additionalUserInfo;
      if (isNewUser) await this.createUser(uid, displayName, email, photoURL);
      if (!emailVerified) sendEmailVerification();
    }
    catch (e) { console.log(e); console.error(e); this.alert.present('', e); }
  }

  async signInWithApple() {
    try {
      const { SignInWithApple } = Plugins;
      const { response } = await SignInWithApple.Authorize();
      const idToken = response.identityToken;
      const provider = new firebase.auth.OAuthProvider('apple.com');
      const credential = provider.credential({ idToken });
      const { user, additionalUserInfo } = await this.auth.signInWithCredential(credential);
      const { uid, displayName, photoURL, email, emailVerified, sendEmailVerification } = user;
      const { isNewUser } = additionalUserInfo;
      if (isNewUser) await this.createUser(uid, displayName, email, photoURL);
      if (!emailVerified) sendEmailVerification();
    }
    catch (e) { console.log(e); }
  }
}
