import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PluralPipe } from './plural/plural.pipe';
import { FromNowPipe } from './from-now/from-now.pipe';
import { CountryEmojiPipe } from './country-emoji/country-emoji.pipe';
import { CountryNamePipe } from './country-name/country-name.pipe';

@NgModule({
  declarations: [PluralPipe, FromNowPipe, CountryEmojiPipe, CountryNamePipe],
  imports: [IonicModule],
  exports: [PluralPipe, FromNowPipe, CountryEmojiPipe, CountryNamePipe]
})
export class PipesModule {}